import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";

import "./styles.scss";
import { Navbar } from "../Navbar";
import { Home } from "../Home";
import { Footer } from "../Footer";
import messages from "../../translations/hr.json";

function App() {
  React.useEffect(() => {
    const title = document.createElement("title");
    title.innerText = messages["sigmaplan.title"];
    document.body.appendChild(title);
  }, []);

  return (
    <IntlProvider locale="hr" defaultLocale="hr" messages={messages}>
      <BrowserRouter>
        <Navbar />
        <div className="content-container">
          <Route exact path="/" component={Home} />
        </div>
        <Footer />
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
