import "./styles.scss";
import { useFormatMessage } from "../../utils/hooks";

export const Home = () => {
  return (
    <div className="home-container">
      <div className="home-gallery">
        <img src="/images/sigma-plan-logo.png" alt="sigmaPlanLogo" />
        <img
          src="/images/wallpapers/construction-site-2.jpg"
          alt="craneViewPicture"
        />
      </div>

      <div className="home-text">
        {useFormatMessage({ id: "home.main-text" })}
      </div>
    </div>
  );
};
