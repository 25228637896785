import { Link } from "react-router-dom";

import "./styles.scss";

import { CroatianCheckerboard } from "../../components/CroatianCheckerboard";
import { useFormatMessage } from "../../utils/hooks";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="main-section">
        <CroatianCheckerboard
          className="cro-chequy"
          rows={2}
          size="50px"
          width="25px"
        />
        <div className="footer-column">
          <Link className="footer-link" to="/">
            {useFormatMessage({ id: "sigmaplan.title" })}
          </Link>
          <a
            className="footer-link"
            href="mailto:info@sigmaplan.hr"
            target="_blank"
            rel="noreferrer"
          >
            {useFormatMessage({ id: "sigmaplan.email" })}
          </a>

          <a
            className="footer-link"
            href="https://www.google.com/maps/place/%C5%A0etali%C5%A1te+150.+brigade+Hrvatske+vojske+5,+10000,+Zagreb/@45.7978984,15.9017074,16.31z/data=!4m13!1m7!3m6!1s0x4765d14508b00cbb:0x75cda0a666cd7ded!2s%C5%A0etali%C5%A1te+150.+brigade+Hrvatske+vojske+5,+10000,+Zagreb!3b1!8m2!3d45.798024!4d15.9062585!3m4!1s0x4765d14508b00cbb:0x75cda0a666cd7ded!8m2!3d45.798024!4d15.9062585"
            target="_blank"
            rel="noreferrer"
          >
            {useFormatMessage({ id: "sigmaplan.address" })}
          </a>
          <a
            className="footer-link"
            href="https://sudreg.pravosudje.hr/registar/f?p=150:28:0::NO:28:P28_SBT_MBS:081247017"
            target="_blank"
            rel="noreferrer"
          >
            {useFormatMessage({ id: "sigmaplan.oib" })}
          </a>
        </div>
      </div>
      <div className="legal-section footer-column">
        <Link className="footer-link" to="/">
          {useFormatMessage({ id: "footer.privacy-policy" })}
        </Link>
        <Link className="footer-link" to="/">
          {useFormatMessage({ id: "footer.terms-of-use" })}
        </Link>
        <div className="footer-link">
          {useFormatMessage({ id: "footer.copyright" })}
        </div>
      </div>
    </div>
  );
};
