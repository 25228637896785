import React from "react";

import "./styles.scss";
import { CroatianCheckerboard } from "../../components/CroatianCheckerboard";

export const Navbar = () => {
  return (
    <div className="navbar">
      <CroatianCheckerboard
        className="cro-chequy"
        rows={4}
        size="40px"
        width="10px"
        whiteFirst={true}
      />
    </div>
  );
};
