import React from "react";

/**
 *
 * @param {number} rows Number of rows in checkerboard. Checkerboard is square-sized.
 * @param {number} blockWidth Block/cell size in px. Cell is also square-sized.
 * @param {boolean} whiteFirst Is the cell on the first row and the first column white or not.
 * @param {*} props Rest of the props which can be modified. Class name is one of those properties.
 * @returns {ReactNode} Returns a React component CroatiaCheckerboard.
 *
 * @author ftodoric
 */
export const CroatianCheckerboard = ({
  rows,
  size,
  width,
  whiteFirst,
  ...props
}) => {
  let board = [];

  for (let i = 0; i < rows; i++) {
    board.push(`${100 / rows}%`);
  }
  console.log(board);

  const compareExpr = (i, j) => {
    return whiteFirst ? (i + j) % 2 !== 0 : (i + j) % 2 === 0;
  };

  return (
    <div
      className={props.className}
      style={{
        display: "grid",
        width: size,
        height: size,
        gridTemplateRows: board.join(" "),
        gridTemplateColumns: board.join(" "),
      }}
    >
      {board.map((_, i) => {
        return board.map((_, j) => {
          return (
            <div
              key={i + "" + j}
              style={{
                backgroundColor: compareExpr(i, j) ? "firebrick" : "white",
                width: width,
                height: width,
              }}
            ></div>
          );
        });
      })}
    </div>
  );
};
